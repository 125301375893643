import React, { useMemo } from 'react'
import { findParentCategory } from '../../utils/findParentCategory'

const { useSelector } = require('react-redux')
const { useLocation, useNavigate } = require('react-router-dom')

const categories = [
  {
    id: '1',
    title: 'All',
    path: '/categories'
  },
  {
    id: '2',
    title: 'Detective',
    path: '/genre/Detective'
  },
  {
    id: '3',
    title: 'Action',
    path: '/genre/action'
  },
  {
    id: '4',
    title: 'Comedy',
    path: '/genre/comedy'
  },
  {
    id: '5',
    title: 'Family',
    path: '/genre/family'
  },
  {
    id: '4',
    title: 'Thriller',
    path: '#'
  },
  {
    id: '7',
    title: 'Horror',
    path: '#'
  },
  {
    id: '8',
    title: 'Drama',
    path: '/genre/drama'
  },
  {
    id: '9',
    title: 'Classic',
    path: '/genre/classic'
  },
  {
    id: '10',
    title: 'Romantic',
    path: '/genre/romance'
  }
]

function CategoryNav () {
  const { navSettings, lang } = useSelector((state) => ({
    navSettings: state?.pageSettings?.navigation,
    lang: state.page?.lang
  }))
  const navigate = useNavigate()
  const location = useLocation()

  const moviesOrShows = useMemo(
    () =>
      findParentCategory(
        location.pathname,
        navSettings?.primary
      ).title?.toLowerCase() || '',
    [location.pathname, navSettings]
  )

  const primaryLinks = navSettings?.primary?.find(
    (el) => el?.title?.toLowerCase() === moviesOrShows
  )

  const genreLinks = primaryLinks?.items?.map((el) => ({
    title: el.title,
    path: el.url
  }))

  const bengData = primaryLinks?.items?.map((el) => ({
    title: el.localizationMap.bn.title,
    path: el.url
  }))

  const categoryData =
    typeof genreLinks === 'object' && Object.keys(genreLinks).length
      ? genreLinks
      : categories

  const categoryBnData =
    typeof bengData === 'object' && Object.keys(bengData).length
      ? [...bengData]
      : categories

  const handleCategoryClick = (e, { path }) => {
    e.persist()
    navigate(path)
  }

  const { pathname } = location

  const moviesData = categoryData.map((el) => el.title).includes('All Movies')
  const moviesBenData = categoryBnData
    .map((el) => el.title)
    .includes('অল মুভিজ ')
  if (moviesData || moviesBenData) {
    categoryData.unshift(categoryData.pop())
    categoryBnData.unshift(categoryBnData.pop())
  }

  return (
    <div className='category-carousel-category-bar'>
      <ul className='category-carousel-category-list'>
        {(lang === 'en' || lang === 'default') &&
          categoryData.map(
            (category, index) =>
              category.title && (
                <li
                  key={index}
                  className={`category-carousel-category-list-item ${
                    category.path.includes(pathname) ? 'active' : ''
                  }`}
                  onClick={(e) => {
                    handleCategoryClick(e, category)
                  }}
                >
                  {category.title}
                </li>
              )
          )}
        {lang === 'bn' && (
          <>
            {categoryBnData.map(
              (category, index) =>
                category.title && (
                  <li
                    key={index}
                    className={`category-carousel-category-list-item ${
                      category.path.includes(pathname) ? 'active' : ''
                    }`}
                    onClick={(e) => {
                      handleCategoryClick(e, category)
                    }}
                  >
                    {category.title}
                  </li>
                )
            )}
          </>
        )}
      </ul>
    </div>
  )
}

export default CategoryNav
