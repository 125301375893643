import React from 'react';
import '~/client/components/Icons/styles.css';
export default () => (
  <svg viewBox="0 0 26 26">
    <g
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g stroke="#B2B2B4" strokeWidth="2">
        <g transform="translate(-104 -27) translate(105 28)">
          <circle cx="10.667" cy="10.667" r="10.667" />
          <path d="M24 24L18.2 18.2" />
        </g>
      </g>
    </g>
  </svg>
);
