const CustomListener = {
  on: function (eventName, fn) {
    this.element = document.querySelector('#root');
    this.element.addEventListener(eventName, fn);
  },
  broadcast: function (eventName, payload) {
    this.element = document.querySelector('#root');
    this.element.dispatchEvent(
      new CustomEvent(eventName, {
        detail: {
          payload: payload,
        },
      })
    );
  },
};

export default CustomListener;
