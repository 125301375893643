import React, { useEffect, useState, useRef, useMemo } from 'react';
import loadable from '@loadable/component';
import { NavLink, Link, useNavigate, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { Dialog } from '@reach/dialog';
import headerlogo from '../../assets/images/headerlogo.svg';
import './styles.css';
import { setPopup } from '../../redux/actions/popupsActions';
import CustomEvent from '../CustomListener';
import HemburgMenuicon from '../Icons/HemburgMenuicon';
import SearchIcon from '../Icons/SearchIcon';
import { findParentCategory } from '../../utils/findParentCategory';
import CategoryNav from './CategoryNav';

function Navbar({
  user,
  mweb,
  graminUser,
  navSettings,
  discover,
  menu,
  search,
  languageSettings,
  setPopup,
  lang
}) {
  const langLoc = lang === 'bn' ? lang : 'en';
  const [openLogin, setOpenLogin] = useState(false);
  const [openPaymentPopup, setOpenPaymentPopup] = useState(false);
  const desktopHeaderRef = useRef(null);

  // const LoginComponent = loadable(() => import('../../popups/OTPLogin'));
  const MenuComponent = loadable(() => import('../../popups/Menu'));
  const SearchComponent = loadable(() => import('../../popups/SearchLayout'));
  const DiscoverMweb = loadable(() => import('../DiscoverMweb'));
  const PaymentCallbackPopup = loadable(() =>
    import('../../popups/PaymentCallbackPopup')
  );
  const ForgotPasswordComponent = loadable(() =>
    import('../../popups/ForgotPassword')
  );

  const location = useLocation();

  const parentCategory = useMemo(
    () =>
      findParentCategory(
        location.pathname,
        navSettings?.primary
      ).title?.toLowerCase() || '',
    [location.pathname, navSettings]
  );

  const showCategorybar = parentCategory !== '';

  const getContentNavItems = (defaultVal, contentKey) => {
    if (!navSettings) return defaultVal;
    if (!navSettings.primary) return defaultVal;
    const content = navSettings.primary.filter(
      ({ title }) => title === contentKey
    );
    if (content.length === 0) return defaultVal;
    return content[0]?.localizationMap?.[langLoc]?.title || defaultVal;
  };

  const getContentLogedInUser = (defaultVal, contentKey) => {
    if (!navSettings) return defaultVal;
    if (!navSettings.user) return defaultVal;
    const content = navSettings.user.filter(({ url }) => url === contentKey);
    if (content.length === 0) return defaultVal;
    return content[0]?.localizationMap?.[langLoc]?.title || defaultVal;
  };

  const getContentFooterItems = (defaultVal, contentKey) => {
    if (!navSettings) return defaultVal;
    if (!navSettings.footer) return defaultVal;
    // if(contentKey.includes('https') return defaultVal;

    const content = navSettings.footer.filter(({ url }) => url === contentKey);
    if (content.length === 0) return defaultVal;
    return content[0]?.localizationMap?.[langLoc]?.title || defaultVal;
  };

  const getContentSearchPlaceholder = (defaultVal, contentKey) => {
    if (!navSettings) return defaultVal;
    if (!navSettings.right) return defaultVal;
    const content = navSettings.right.filter(
      ({ title }) => title === contentKey
    );
    if (content.length === 0) return defaultVal;
    return content[0]?.localizationMap?.[langLoc]?.title || defaultVal;
  };

  const getContentUserItems = (defaultVal, contentKey) => {
    if (!navSettings) return defaultVal;
    if (!navSettings.settings) return defaultVal;
    if (!navSettings.settings.localizationMap) return defaultVal;
    if (!navSettings.settings.localizationMap?.[langLoc]) return defaultVal;
    if (!navSettings.settings.localizationMap?.[langLoc].primaryCta) {
      return defaultVal;
    }
    return (
      navSettings?.settings?.localizationMap?.[langLoc]?.primaryCta?.[
        contentKey
      ] || defaultVal
    );
  };

  useEffect(() => {
    CustomEvent.on('open-login', (e) => {
      setOpenLogin(true);
    });
  }, []);

  const NavLinkContainerRef = useRef(null);

  useEffect(() => {
    // try {
    //   const navLiActive =
    //     NavLinkContainerRef.current?.querySelector('.nav-li-active')
    //   navLiActive && navLiActive.classList?.remove('nav-li-active')
    //   NavLinkContainerRef.current
    //     ?.querySelector('a.active')
    //     ?.parentElement?.classList?.add('nav-li-actishowsve')
    //   setTimeout(() => {
    //     document.querySelector('a.active')?.scrollIntoView()
    //   }, 1000)
    // } catch (error) {}

    window.addEventListener('scroll', function () {
      if (window.scrollY >= 250) {
        desktopHeaderRef.current?.classList?.add?.('bg-black');
      } else {
        desktopHeaderRef.current?.classList?.remove?.('bg-black');
      }
    });
  }, []);

  // useEffect(() => {
  //   console.log('redirected here ',location.hash)
  //   try {
  //     if (['#shows', '#movies'].includes(location.hash)) {
  //       const aTag = NavLinkContainerRef.current.querySelector(
  //         `a[href$="${location.hash}"]`
  //       )
  //       aTag.classList.add('active')
  //       console.log({aTag})
  //     }
  //   } catch (error) {}
  // }, [location.hash])
  const history = useNavigate();
  const defaultLinks = {
    '/shows': 'Shows',
    '/categories': 'Movies',
    '/free': 'Free',
    '/referral': 'Refer and Earn',
    '/upcoming': 'Upcoming'
  };

  let userCurrentState;
  if (user && user?.isSubscribed !== undefined) {
    if (user?.isSubscribed) {
      userCurrentState = 1;
      //  1 for subscribed
    } else {
      userCurrentState = 2;
      //  2 for unsubscribed
    }
  } else {
    //  3 for loggedout
    userCurrentState = 3;
  }

  const handleMobileHamberger = () => {
    if (window.location.pathname === '/user') {
      history(-1);
    } else history('/user');
  };
  const submenu = navSettings?.primary;
  const subMenuData = submenu?.slice(0, submenu.length - 1);
  const navlinks = subMenuData?.map((el, idx) => {
    const { url, title } = el;
    const _url = url;
    let isLinkActive = false;

    if (
      (url === '/categories' && parentCategory === 'movies') ||
      (url === '/shows' && parentCategory === 'shows')
    ) {
      isLinkActive = true;
    }

    if (el?.accessLevels?.subscribed && userCurrentState === 1) {
      return (
        <li key={idx}>
          {url?.includes('https://') ? (
            <a
              key={idx}
              href={_url}
              target={el.newTab && '_blank'}
              rel="noopener noreferrer"
              // activeClassName="active"
              className={mweb ? 'navbar-mobo-v2-list-item' : 'a'}
            >
              {getContentNavItems(defaultLinks[url], title)}
            </a>
          ) : (
            <NavLink
              key={idx}
              to={_url}
              end
              // activeClassName="active"
              className={
                (mweb ? 'navbar-mobo-v2-list-item' : 'a') +
                (isLinkActive ? ' active' : '')
              }
            >
              {getContentNavItems(defaultLinks[url], title)}
            </NavLink>
          )}
        </li>
      );
    }
    if (el?.accessLevels?.loggedIn && userCurrentState === 2) {
      return (
        <li key={idx}>
          {url?.includes('https://') ? (
            <a
              key={idx}
              href={_url}
              target={el.newTab && '_blank'}
              rel="noopener noreferrer"
              // activeClassName="active"
              className={mweb ? 'navbar-mobo-v2-list-item' : 'a'}
            >
              {getContentNavItems(defaultLinks[url], title)}
            </a>
          ) : (
            <NavLink
              key={idx}
              to={_url}
              end
              // activeClassName="active"
              className={
                (mweb ? 'navbar-mobo-v2-list-item' : 'a') +
                (isLinkActive ? ' active' : '')
              }
            >
              {getContentNavItems(defaultLinks[url], title)}
            </NavLink>
          )}
        </li>
      );
    }
    if (el?.accessLevels?.loggedOut && userCurrentState === 3) {
      return (
        <li key={idx}>
          {url?.includes('https://') ? (
            <a
              key={idx}
              href={_url}
              target={el.newTab && '_blank'}
              rel="noopener noreferrer"
              // activeClassName="active"
              className={mweb ? 'navbar-mobo-v2-list-item' : 'a'}
            >
              {getContentNavItems(defaultLinks[url], title)}
            </a>
          ) : (
            <NavLink
              key={idx}
              end
              to={_url}
              // activeClassName="active"
              className={
                (mweb ? 'navbar-mobo-v2-list-item' : 'a') +
                (isLinkActive ? ' active' : '')
              }
            >
              {getContentNavItems(defaultLinks[url], title)}
            </NavLink>
          )}
        </li>
      );
    }
    return null;
  });

  return (
    <>
      {graminUser ? null : (
        <div className="header-container">
          <div>
            {!mweb && (
              <div className="desktop-header" ref={desktopHeaderRef}>
                <Link to="/" className="logo-style">
                  {/* <img
                  src={logo140X40}
                  alt="hoichoi brand logo"
                  className="header-logo"
                /> */}
                  <img
                    src="https://legacy.asset.viewlift.com/7fa0ea9a-9799-4417-99f5-cbb5343c551d/images/2024/07/15/1721036413210_hoichoilogo_140x40_dih4zunhimages-tab.webp?impolicy=resize&w=140&h=40"
                    alt="hoichoi brand logo"
                    className="header-logo"
                    fetchpriority="high"
                  />
                </Link>
                <div className="menu-styles">
                  <div className="header-primary-menu">
                    <ul
                      className="header-menu-container"
                      ref={NavLinkContainerRef}
                    >
                      {navlinks}
                    </ul>
                  </div>

                  <div className="header-secondary-menu">
                    <ul className="header-menu-container">
                      <li className="margin-20">
                        {/* <a className='a' role='button'> */}
                        <button
                          className="nav-search-button"
                          type="button"
                          onClick={() => history('/search')}
                        >
                          {/* <span>Search</span> */}
                          <span className="nav-search-svg-icon-container">
                            <svg
                              version="1.1"
                              x="0px"
                              y="0px"
                              viewBox="0 0 512 512"
                            >
                              <g>
                                <g>
                                  <path
                                    d="M225.474,0C101.151,0,0,101.151,0,225.474c0,124.33,101.151,225.474,225.474,225.474
                                  c124.33,0,225.474-101.144,225.474-225.474C450.948,101.151,349.804,0,225.474,0z M225.474,409.323
                                  c-101.373,0-183.848-82.475-183.848-183.848S124.101,41.626,225.474,41.626s183.848,82.475,183.848,183.848
                                  S326.847,409.323,225.474,409.323z"
                                  />
                                </g>
                              </g>
                              <g>
                                <g>
                                  <path
                                    d="M505.902,476.472L386.574,357.144c-8.131-8.131-21.299-8.131-29.43,0c-8.131,8.124-8.131,21.306,0,29.43l119.328,119.328
                                                      c4.065,4.065,9.387,6.098,14.715,6.098c5.321,0,10.649-2.033,14.715-6.098C514.033,497.778,514.033,484.596,505.902,476.472z"
                                  />
                                </g>
                              </g>
                            </svg>

                            {/* <SearchIcon /> */}
                          </span>
                        </button>
                        {/* </a> */}
                      </li>
                      {!(user && user.auth) && (
                        <li className="margin-20">
                          <span
                            className="a"
                            role="button"
                            onClick={() => {
                              setPopup('login');
                            }}
                          >
                            {getContentUserItems('Login', 'loginCtaText')}
                          </span>
                        </li>
                      )}
                      {!user?.isSubscribed && (
                        <li className="navbar-subscribe-btn-li">
                          <Link to="/subscribe" className="a">
                            <button
                              className="primary-button navbar-subscribe-btn"
                              type="button"
                            >
                              {getContentUserItems('SUBSCRIBE', 'ctaText')}
                            </button>
                          </Link>
                        </li>
                      )}
                      <li
                        onClick={() => {
                          setPopup('menu');
                          // setOpenMenu(true);
                        }}
                        role="button"
                        aria-hidden="true"
                        className="navbar-user-icon-li"
                      >
                        <div className="navbar-hemburg-menu-icon">
                          <HemburgMenuicon />
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            )}

            {mweb && (
              <div className="navbar-mobo-v2-container">
                <div className="navbar-mobo-v2-wrapper">
                  <div className="nabar-mobo-v2-parent">
                    <div
                      className="navbar-mobo-v2-menu-hemburger"
                      onClick={handleMobileHamberger}
                    >
                      <HemburgMenuicon />
                    </div>
                    <div className="navbar-mobo-v2-logo-container">
                      {/* <NavLink  to="/" activeClassName="active_no_need"> */}
                      <NavLink to="/">
                        <div className="navbar-mobo-v2-list-item margin-right-10-0">
                          {/* {getContentNavItems('Home', 'Home')} */}
                          <img
                            width={80}
                            height={45}
                            src={headerlogo}
                            alt="logo"
                            className="navbar-mobo-v2-logo"
                            fetchPriority="high"
                          />
                        </div>
                      </NavLink>
                    </div>
                    <ul className="navabar-mobo-v2-list">
                      {/* <NavLink exact to="/" activeClassName="active_no_need">
                    <li className="navbar-mobo-v2-list-item margin-right-10">
                      {/* {getContentNavItems('Home', 'Home')} * /}
                      <img src={logo140X40} className="navbar-mobo-v2-logo" />
                    </li>
                  </NavLink> */}
                      {navlinks}
                    </ul>
                    <div
                      className="navbar-mobo-v2-search"
                      onClick={() => history('/search')}
                    >
                      {/* {!(user && user.auth) && (
                        // <li className="margin-20">
                        <button
                          className='login-mweb'
                          role='button'
                          onClick={(e) => {
                            e.stopPropagation()
                            setPopup('login')
                          }}
                        >
                          {getContentUserItems('Login', 'loginCtaText')}
                        </button>
                        // </li>
                      )}
                      {!user?.isSubscribed && (
                        // <li className="navbar-subscribe-btn-li">
                        <Link to='/subscribe' className='a'>
                          <button
                            className='subscribe-btn-mweb'
                            type='button'
                            onClick={(e) => e.stopPropagation()}
                          >
                            {getContentUserItems('SUBSCRIBE', 'ctaText')}
                          </button>
                        </Link>
                        // </li>
                      )} */}
                      <SearchIcon />
                    </div>
                  </div>
                </div>
              </div>
            )}
            <Dialog
              isOpen={discover === true}
              onDismiss={() => discover && setPopup('discover')}
            >
              <DiscoverMweb
                onBackClick={() => discover && setPopup('discover', false)}
              />
            </Dialog>

            {menu && (
              <MenuComponent
                getContentUserItems={getContentUserItems}
                getContentFooterItems={getContentFooterItems}
                getContentLogedInUser={getContentLogedInUser}
                openMenu={menu}
                languageSettings={languageSettings?.languages}
                onMenuClosed={() => setPopup('menu')}
              />
            )}
            {/* {login && LoginComponent && (
          <LoginComponent
            mweb={mweb}
            openLogin={login}
            onLoginClose={() => setPopup('login')}
          />
        )} */}
            {search && (
              <SearchComponent
                getContentSearchPlaceholder={getContentSearchPlaceholder}
                openSearch={search}
                onSearchClosed={() => setPopup('search')}
              />
            )}

            {openPaymentPopup && (
              <PaymentCallbackPopup
                onCloseClick={setOpenPaymentPopup}
                heading="Payment Processing"
                subheading="Pease wait while we are processing your payment."
                ctaText="Continue"
                onCtaClick={() => {
                  setOpenPaymentPopup(false);
                }}
                loading
              />
            )}
          </div>
          {showCategorybar && <CategoryNav />}
        </div>
      )}
    </>
  );
}

export default connect(
  (state) => {
    return {
      user: state.user,
      login: state.popups?.login,
      menu: state.popups?.menu,
      search: state.popups?.search,
      discover: state.popups?.discover,
      navSettings: state?.pageSettings?.navigation,
      languageSettings: state?.pageSettings?.languages,
      pageSettings: state?.pageSettings,
      graminUser: state?.graminUser?.data,
      lang: state.page?.lang
    };
  },
  { setPopup }
)(Navbar);
